<template>
    <div class="container">
        <div class="container_block">
            <div class="container_block_title">
              <div class="container_block_title_info">
                <div class="container_block_title_info_top">{{name}}</div>
                <div class="container_block_title_info_bottom">{{address}}</div>
              </div> <span class="container_block_title_date" @click="confirmBtn">分站主页</span></div>
             <el-divider />
             <div class="container_block_content" v-html="content">
                   </div>
        </div>
    </div>
</template>
<script setup name="StationDetail">
import {ref} from "@vue/reactivity";
import {useRoute, useRouter} from "vue-router";
import { useStore } from 'vuex';
import {getStationById} from "@/api/station";
const store = useStore();
const route = useRoute();
const router = useRouter();
const id = route.query.id;
const name= ref('');
const address= ref('');
const content=ref('');
const getDetail= async ()=>{
  let response= await getStationById(id);
  if(response.code === 200){
    name.value = response.data.name;
    address.value = response.data.address;
    content.value = response.data.description;
  }
};
getDetail()
const confirmBtn=  ()=>{
  console.error(id);
  store.commit("setStationIds",id);
  localStorage.setItem("stationName", name.value);
  router.push({
    path: "/",
  });
}

</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  padding-top: 30px;
  &_block {
    margin: 0 auto;
    padding: 30px 30px 30px 30px;
    width: 62%;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &_title{
        width: 100%;
        color: #333333;
        font-size: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &_info{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          &_top{
            font-size: 20;
            color: #212121;
            font-weight: bold;
          }
          &_bottom{
            margin-top: 20px;
            font-size: 14px;
            color: #424242;
          }
        }
        &_date{
          width: 240px;
          height: 60px;
          background: url("../../assets/images/fzbtn.png") no-repeat;
          background-size: 100% 100%;
          font-size: 20px;
          color: #3B2F00;
          text-align: center;
          vertical-align: middle;
          line-height: 60px;
        }
    }
    &_row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &_content{
        font-size: 16px;
        color: #424242;
        line-height: 30px;
        text-align: left;
    }

  }
}
</style>