// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/fzbtn.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".container[data-v-0adb8211]{width:100%;height:100vh;background-color:#f5f5f5;padding-top:.15625rem}.container_block[data-v-0adb8211]{margin:0 auto;padding:.15625rem .15625rem .15625rem .15625rem;width:62%;background:#fff;border-radius:.03125rem;display:flex;flex-direction:column;align-items:flex-start}.container_block_title[data-v-0adb8211]{width:100%;color:#333;font-size:.10417rem;display:flex;flex-direction:row;justify-content:space-between;align-items:center}.container_block_title_info[data-v-0adb8211]{display:flex;flex-direction:column;align-items:flex-start}.container_block_title_info_top[data-v-0adb8211]{font-size:20;color:#212121;font-weight:700}.container_block_title_info_bottom[data-v-0adb8211]{margin-top:.10417rem;font-size:.07292rem;color:#424242}.container_block_title_date[data-v-0adb8211]{width:1.25rem;height:.3125rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;font-size:.10417rem;color:#3b2f00;text-align:center;vertical-align:middle;line-height:.3125rem}.container_block_row[data-v-0adb8211]{display:flex;flex-direction:row;justify-content:space-between}.container_block_content[data-v-0adb8211]{font-size:.08333rem;color:#424242;line-height:.15625rem;text-align:left}", ""]);
// Exports
module.exports = exports;
